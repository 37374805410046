<template>
  <div class="chat">
    <div class="msg-list" ref="msgList">
      <!-- 对话 -->
      <div
        class="msg"
        :class="{ user: item.role === 'user' }"
        v-for="(item, index) in messageList"
        :key="index"
      >
        <div class="info">
          <img
            class="avatar-icon"
            v-if="item.role == 'assistant'"
            :src="avatar"
            alt=""
          />

          <img
            class="avatar"
            v-else
            src="../../assets/image/userAvatar.jpg"
            alt=""
          />
          <div class="name">
            {{ item.role == "assistant" ? "美湾AI" : "我" }}
          </div>
        </div>
        <div
          class="content"
          v-if="item.role == 'assistant'"
          v-html="getDom(item.content)"
        ></div>
        <div class="content user" v-else>
          {{ item.content }}
        </div>

        <div
          class="info_box"
          v-if="
            (messageList.length == 2 && props.type == 1 && !socketIsOpen) ||
            showSafe
          "
        >
          <div
            class="info_btn"
            @click="
              value = '请对该配方的法规风险，及可能出现的风险物质进行评估';
              showSafe = false;
              handleSubmit();
            "
          >
            安全评估
          </div>
          <div
            class="info_btn"
            @click="
              value =
                '请根据上面的配方方案，生成该配方的详细报价表以及对应厂商和联系方式';
              showSafe = true;
              handleSubmit();
            "
          >
            原料报价
          </div>
        </div>
      </div>
      <!-- 入口 -->
      <div
        class="hello"
        v-if="
          props.type == 0 &&
          !socketIsOpen &&
          messageList.length <= 0 &&
          !history
        "
      >
        <div class="title">你好，我是美湾大模型</div>
        <div class="tip">
          我将提供专业的咨询及检测服务，助力化妆品企业加速产品研发上市
          降低合规风险
        </div>
        <div class="value_box">
          <div class="left"></div>
          <div class="right">
            <div
              class="r_item"
              v-for="(item, index) in value_box"
              :key="index"
              @click="changeType(item)"
            >
              <div class="r_name">
                {{ item.name }}
              </div>
              <p class="r_tip">{{ item.tip }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="safe"
        v-if="props.type == 3 && !socketIsOpen && messageList.length <= 0"
      >
        <div class="mes">
          <div class="name">
            <img class="icon" src="../../assets/image/safe.png" alt="" />
            安全评估
          </div>
          <div class="tip">
            基于法规和物质信息，生成化妆品安全评估报告，技术团队为报告的客观性与准确性保驾护航
          </div>
        </div>
        <div class="help">
          <div class="tip">我可以如何帮到你？</div>
          <div
            class="item"
            v-for="(item, index) in helpList"
            :key="index"
            @click="selectHelp(item)"
          >
            {{ index + 1 }}.{{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="handleAdd" @click="handleAdd">
      <img class="icon" src="../../assets/image/add.png" alt="" />
      新建对话
    </div>
    <div class="chat-container">
      <div class="textarea-container">
        <textarea
          rows="1"
          ref="textarea"
          v-model="value"
          placeholder="向我提问吧"
          @input="adjustHeight"
          @keydown="handleKeyCode($event)"
        ></textarea>
        <div
          class="btn"
          @click="handleSubmit"
          :class="{ waiting: isWaiting }"
        ></div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import {
  watch,
  computed,
  onMounted,
  reactive,
  ref,
  defineProps,
  defineEmits,
  nextTick,
} from "vue";
import { getTextDom } from "@/utils/tools";
import md5 from "@/config/md5.min";
import { ElMessage } from "element-plus";

import * as Api from "@/api/index";
import { useStore } from "vuex";
let store = useStore();
const emit = defineEmits(["handleAdd", "refreshMessageList", "changeType"]);
const props = defineProps({
  type: Number,
});
const textarea = ref(null);
const msgList = ref(null);
const value = ref("");
const isWaiting = ref(false);
const socketIsOpen = ref(false);
const socket = ref(null);
const messageList = ref([]);
const showSafe = ref(false);
const helpList = ref([
  "化妆品生产中，如何确保原料的安全性和合规性?",
  "在法规合规方面，我需要关注哪些具体的化妆品法规和标准?",
  "在化妆品生产过程中，有哪些常见的违规行为需要避免?",
  "根据《化妆品生产经营监督管理办法》，申请化妆品生产许可证，怎么操作",
  "该配方在中国是否允许销售和生产",
]);
const value_box = ref([
  {
    name: "配方助手",
    tip: "配方助手可根据产品功效宣称、适用人群、类别等，帮助企业进行配方审核和风险管理",
    id: 1,
  },
  {
    name: "原料分析",
    tip: "涵盖原料CAS号、智能组合产品配方、存在风险等多维度信息。助企业快速判断某种原料等法规要求。",
    id: 2,
  },
  {
    name: "安全评估",
    tip: "基于法规和物质信息，生成化妆品安全评估报告，技术团队为报告的客观性与准确性保驾护航",
    id: 3,
  },
]);
watch(
  socketIsOpen,
  (New, Old) => {
    store.dispatch("setSocketIsOpen", New);
  },
  { immediate: true }
);
const getDom = (text) => {
  return getTextDom(text);
};
const avatar = computed(() => {
  if (props.type == 3) {
    return require("../../assets/image/safe.png");
  } else if (props.type == 2) {
    return require("../../assets/image/yuanliao.png");
  } else {
    return require("../../assets/image/peifang.png");
  }
});
const adjustHeight = () => {
  textarea.value.style.height = "auto";
  textarea.value.style.overflow = "auto";
  textarea.value.style.height = textarea.value.scrollHeight + "px";
  textarea.value.style.lineHeight = 1;
};
const handleKeyCode = (event) => {
  console.log(event);
  if (event.keyCode === 13) {
    if (!event.ctrlKey) {
      event.preventDefault();
      handleSubmit();
    } else {
      value.value += "\n";
    }
  }
};
const chatSendMsg = () => {
  let sign = md5(
    Math.floor(Date.now() / 1000) +
      "TiYksjekO4zAgw6ui1ikdjejDBIKrxK5aOLpg4EEsQL4hBMrEgdFhs"
  );

  const list = [...messageList.value].filter((item) => !item.loading);

  socket.value.send(
    JSON.stringify({
      status: 200,
      report_id: 40001,
      timestamp: Math.floor(Date.now() / 1000).toString(),
      sign: sign,
      messages: list,
    })
  );
  setTimeout(() => {
    saveMessage("user", value.value);
    value.value = "";
    textarea.value.style.height = "";
    textarea.value.style.overflow = "";
    textarea.value.style.height = "";
    textarea.value.style.lineHeight = "";
  }, 300);
};
const disconnectSocket = () => {
  stop();
  messageList.value = [];
};
const stop = () => {
  isWaiting.value = false;
  socketIsOpen.value = false;
  if (socket.value) {
    socket.value.close();
    socket.value = null;
  }
};

const socketInit = () => {
  if (socketIsOpen.value) {
    chatSendMsg();
  } else {
    const path = "wss://jlmodel.huashuyunai.com:8091/chat/queryhistory";
    socket.value = new WebSocket(path);
    socket.value.onopen = () => {
      socketIsOpen.value = true;
      chatSendMsg();
    };

    socket.value.onmessage = (data) => {
      const message = JSON.parse(data.data);
      const index = messageList.value.length - 1;
      const item = messageList.value[index];
      if (item.loading) {
        messageList.value.splice(index, 1, {
          content: "",
          role: "assistant",
        });
      } else {
        messageList.value.splice(index, 1, {
          ...item,
          content: item.content + message.messages[0].content,
        });
      }

      scroll();
    };

    socket.value.onerror = () => {
      socketIsOpen.value = false;
      isWaiting.value = false;
      nextTick(() => {
        scroll();
      });
    };
    socket.value.onclose = () => {
      if (messageList.value.length > 0) {
        const index = messageList.value.length - 1;
        const item = messageList.value[index];
        saveMessage("assistant", item.content);
      }
      socketIsOpen.value = false;
      isWaiting.value = false;
      nextTick(() => {
        scroll();
      });
    };
  }
};
const handleSubmitInstructionSet = (e) => {
  value.value = e;
  createConversation(value.value);
  socketInit();
  isWaiting.value = true;

  messageList.value.push({ role: "user", content: e });
  messageList.value.push({
    content: "智能回复中...",
    role: "assistant",
    loading: true,
  });
  nextTick(() => {
    scroll();
  });
};

const handleSubmit = () => {
  if (isWaiting.value) {
    stop();
  }

  if (!value.value) return;
  if (!socketIsOpen.value && messageList.value.length <= 0) {
    // 开启对话
    createConversation(value.value);
  }
  socketInit();
  isWaiting.value = true;

  // console.log(this.value)
  messageList.value.push({ role: "user", content: value.value });
  messageList.value.push({
    content: "智能回复中...",
    role: "assistant",
    loading: true,
  });
  nextTick(() => {
    scroll();
  });
};
const selectHelp = (item) => {
  value.value = item;
  handleSubmit();
};
const scroll = () => {
  const msgListRef = msgList.value;
  if (msgListRef) {
    // 获取.msg-list元素的scrollHeight
    const scrollHeight = msgListRef.scrollHeight;

    // 滚动到.msg-list底部
    // msgListRef.scrollTop = ;
    msgListRef.scrollTo({
      top: scrollHeight,
      behavior: "smooth",
      duration: 1500,
    });
  }
};
const history = ref(false);
const getHistory = async () => {
  history.value = true;
  messageList.value = [];
  const res = await Api.getMessage({
    conversation_id: store.state.conversation_id,
  });
  if (res.status == 200) {
    if (res.data.length <= 0) {
      return;
    }
    let mes = res.data.map((e) => {
      return {
        role: e.role,
        content: e.content,
      };
    });
    messageList.value = [...messageList.value, ...mes];
    nextTick(() => {
      history.value = false;
      scroll();
    });
  }
};
const saveMessage = async (role, content) => {
  const res = await Api.saveMessage({
    form: {
      conversation_id: store.state.conversation_id,
      role: role,
      content: content,
    },
  });
};

const createConversation = async (title) => {
  const res = await Api.createConversation({
    form: {
      title: title,
    },
  });
  if (res.status == 200) {
    console.log(res);
    store.dispatch("setConversationId", res.data.conversation_id);
    emit("refreshMessageList");
  }
};
const handleAdd = () => {
  if (socketIsOpen.value) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return;
  }
  if (props.type == 0 && !socketIsOpen.value && messageList.value.length <= 0) {
    // 没有选中 && 没有开启对话
    ElMessage({
      message: "已切换最新会话",
      type: "warning",
    });
  } else {
    stop();
    store.dispatch("setConversationId", 0);
    emit("handleAdd");
  }
};
const changeType = (item) => {
  emit("changeType", item);
};
defineExpose({
  handleSubmitInstructionSet,
  disconnectSocket,
  getHistory,
});
</script>
  
<style lang="less" scoped>
@media (min-width: 1200px) {
  .chat {
    padding: 43px 100px 37px;
  }
}

/* 视口宽度大于 1600px */
@media (min-width: 1600px) {
  .chat {
    padding: 43px 350px 37px;
  }
}
.chat {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 92vh;

  min-height: 92vh;
  background: #fff;
  padding-top: 43px;
  overflow-y: auto;
  overflow-x: hidden;
  .msg-list::-webkit-scrollbar {
    display: none; /* 对于 Chrome, Safari 和 Opera */
  }
  .msg-list {
    width: 100%;
    margin: 0 auto auto;
    overflow: auto;
    scrollbar-width: none; /* 对于 Firefox */
    -ms-overflow-style: none; /* 对于 Internet Explorer 和 Edge */

    .msg {
      margin-bottom: 20px;
      .info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .avatar-icon,
        .avatar {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        .avatar {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .name {
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
        &.user {
          .avatar {
            width: 30px;
            height: 30px;

            color: #ffffff;
          }
          .content {
            color: #333333;
          }
        }
      }

      .content {
        margin: 0 0 0 40px;
        text-align: justify;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 27px;
        white-space: pre-line;
        &.user {
          display: flex;
          align-items: center;
          padding: 8px 10px;
          -webkit-box-sizing: border-box;
          cursor: pointer;
          &:hover {
            background: #fafafa;
            border-radius: 8px;
          }
        }
      }
      .info_box {
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-top: 14px;
        .info_btn {
          background: #ffffff;
          padding: 6px 14px;
          border-radius: 4px;
          border: 1px solid #e5e5e5;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 17px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #999999;
      margin-bottom: 20px;
      .text {
        margin-left: 5px;
      }
    }

    .hello {
      margin-bottom: 20px;
      .title {
        font-weight: 600;
        font-size: 28px;
        color: #333333;
        line-height: 40px;
        margin-bottom: 8px;
        text-align: center;
      }
      .tip {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        margin-bottom: 30px;
        text-align: center;
      }
      .value_box {
        display: flex;
        .left {
          width: 200px;
          height: 240px;
          border-radius: 8px;
          background: url(../../assets/image/hello.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 8px;
          flex-shrink: 0;
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          .r_item {
            cursor: pointer;
            padding: 14px 20px;
            background: #f4f5f9;
            border-radius: 8px;
            &:hover {
              background: #eaecf5;
            }
            .r_name {
              font-weight: 600;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
            }
            .r_tip {
              font-weight: 400;
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .safe {
      .mes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 800px;
        height: 100px;
        background: url(../../assets//image/safe_bg.png) no-repeat;
        background-size: 100% 100%;
        padding: 0 24px;
        margin: 0 auto;
        .name {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          margin-bottom: 7px;
          .icon {
            width: 30px;
            height: 30px;
            margin-right: 11px;
          }
        }
        .tip {
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 18px;
        }
      }
      .help {
        padding: 0 40px;
        margin-top: 40px;
        .tip {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-bottom: 12px;
        }
        .item {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          height: 46px;
          background: #f4f5f9;
          border-radius: 8px;
          padding: 0 20px;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 18px;
          margin-bottom: 12px;
          &:hover {
            background: #eaecf5;
          }
        }
      }
    }
  }
  .handleAdd {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 36px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 8px;
    cursor: pointer;
    flex-shrink: 0;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
  .chat-container {
    margin: 0 auto;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #f1f2f5;
    background: #fff;
  }

  .textarea-container {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    padding: 14px;
    overflow: hidden; /* 隐藏滚动条 */
  }

  textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    font-size: 14px;
    line-height: 1;
    box-sizing: border-box;
    overflow: hidden; /* 隐藏滚动条 */
    background-color: transparent;
    max-height: 250px; /* 设置最大高度 */
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
  }
  .btn {
    width: 28px;
    height: 28px;
    background: url("../../assets/image/send.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    margin-left: 10px;
    &.waiting {
      background: url("../../assets/image/stop.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}

/deep/ .t_box {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  .table {
    font-size: 20px;
    outline: 0;
    border-collapse: collapse;
    border-spacing: 0;
    tbody {
      display: block;
      width: max-content;
    }
    .tr {
      border-bottom: 1px solid #f0f0f0;

      &:first-child {
        background: #fafafa;
      }
      .td {
        max-width: 448px;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 27px;
        padding: 12px 20px;
        text-align: left;
      }
    }
  }
}
</style>