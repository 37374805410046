<template>
  <div class="page">
    <Menu
      ref="menuRef"
      @type="getType"
      @instructionSet="getInstructionSet"
      @getHistory="getHistory"
    />
    <Chat
      ref="chatRef"
      :type="type"
      @changeType="changeType"
      @handleAdd="handleAdd"
      @refreshMessageList="refreshMessageList"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, defineProps, nextTick } from "vue";
const menuRef = ref(null);
const chatRef = ref(null);
import Menu from "../menu";
import Chat from "../chat";

const type = ref(0);
const handleAdd = () => {
  menuRef.value.handleAdd();
};

const refreshMessageList = () => {
  menuRef.value.getMessageList();
};
const changeType = (value) => {
  menuRef.value.selectType(value);
};
const getHistory = () => {
  chatRef.value.getHistory();
};

const getType = (value) => {
  type.value = value;
  chatRef.value.disconnectSocket();
};
const getInstructionSet = (value) => {
  chatRef.value.handleSubmitInstructionSet(value);
};
</script>
<style lang="less" scoped>
.page {
  display: flex;

  width: 100%;
  height: 100%;
}
</style>