const PRODUCTS = [
  {
    name: '防晒霜', key: 1
  },
  {
    name: '祛皱霜', key: 2
  },
  {
    name: '祛痘霜', key: 3
  },
]
const SPF = [
  15, 30, 50
]

const PA = [
  'PA+', 'PA++', 'PA+++', 'PA++++'
]

const LEVEL = [
  {
    name: '防水', active: false, id: 1
  },
  {
    name: '防汗', active: false, id: 2
  },
]
const OTHER = [
  {
    name: '无刺激', active: false, id: 1
  },
  {
    name: '无香料', active: false, id: 2
  }
]

const SKIN = [
  '油性', '干性', '敏感'
]



const AREA = [
  '中国', '欧洲', '非洲', '北美', '南美'
]
const SCENE = [
  '日常通勤', '户外活动', '海滩'
]

const TBC = [
  '<1000 CFU/g', '<500 CFU/g或CFU/mL'
]

const TMYC = [
  '<100 CFU/g', '<200 CFU/g'
]

const PB = ['<10毫克/千克', '<5毫克/千克']
const AS = ['<2毫克/千克', '<1毫克/千克']
const HG = ['<2毫克/千克', '<1毫克/千克']
const CD = ['<5毫克/千克', '<1毫克/千克']
const NI = ['<0.2微克/平方厘米', '<0.1微克/平方厘米']

const TMYC2 = [
  '<100 CFU/g', '<50 CFU/g'
]
const MCOSI = ['<2.0', '<1.0']
const LSF = ['≤2', '≤1']

export default {
  PRODUCTS,
  SPF,
  PA,
  LEVEL,
  SKIN,
  OTHER,
  AREA, SCENE, TBC, TMYC, PB, AS, HG, CD, NI,
  TMYC2, MCOSI, LSF
}