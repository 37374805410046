import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../assets/image/userAvatar.jpg'
import _imports_1 from '../../assets/image/safe.png'
import _imports_2 from '../../assets/image/add.png'


const _hoisted_1 = { class: "chat" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "avatar",
  src: _imports_0,
  alt: ""
}
const _hoisted_5 = { class: "name" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "content user"
}
const _hoisted_8 = {
  key: 2,
  class: "info_box"
}
const _hoisted_9 = {
  key: 0,
  class: "hello"
}
const _hoisted_10 = { class: "value_box" }
const _hoisted_11 = { class: "right" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "r_name" }
const _hoisted_14 = { class: "r_tip" }
const _hoisted_15 = {
  key: 1,
  class: "safe"
}
const _hoisted_16 = { class: "help" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "chat-container" }
const _hoisted_19 = { class: "textarea-container" }

import {
  watch,
  computed,
  onMounted,
  reactive,
  ref,
  nextTick,
} from "vue";
import { getTextDom } from "@/utils/tools";
import md5 from "@/config/md5.min";
import { ElMessage } from "element-plus";

import * as Api from "@/api/index";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  type: Number,
},
  emits: ["handleAdd", "refreshMessageList", "changeType"],
  setup(__props, { expose: __expose, emit: __emit }) {

let store = useStore();
const emit = __emit;
const props = __props;
const textarea = ref(null);
const msgList = ref(null);
const value = ref("");
const isWaiting = ref(false);
const socketIsOpen = ref(false);
const socket = ref(null);
const messageList = ref([]);
const showSafe = ref(false);
const helpList = ref([
  "化妆品生产中，如何确保原料的安全性和合规性?",
  "在法规合规方面，我需要关注哪些具体的化妆品法规和标准?",
  "在化妆品生产过程中，有哪些常见的违规行为需要避免?",
  "根据《化妆品生产经营监督管理办法》，申请化妆品生产许可证，怎么操作",
  "该配方在中国是否允许销售和生产",
]);
const value_box = ref([
  {
    name: "配方助手",
    tip: "配方助手可根据产品功效宣称、适用人群、类别等，帮助企业进行配方审核和风险管理",
    id: 1,
  },
  {
    name: "原料分析",
    tip: "涵盖原料CAS号、智能组合产品配方、存在风险等多维度信息。助企业快速判断某种原料等法规要求。",
    id: 2,
  },
  {
    name: "安全评估",
    tip: "基于法规和物质信息，生成化妆品安全评估报告，技术团队为报告的客观性与准确性保驾护航",
    id: 3,
  },
]);
watch(
  socketIsOpen,
  (New, Old) => {
    store.dispatch("setSocketIsOpen", New);
  },
  { immediate: true }
);
const getDom = (text) => {
  return getTextDom(text);
};
const avatar = computed(() => {
  if (props.type == 3) {
    return require("../../assets/image/safe.png");
  } else if (props.type == 2) {
    return require("../../assets/image/yuanliao.png");
  } else {
    return require("../../assets/image/peifang.png");
  }
});
const adjustHeight = () => {
  textarea.value.style.height = "auto";
  textarea.value.style.overflow = "auto";
  textarea.value.style.height = textarea.value.scrollHeight + "px";
  textarea.value.style.lineHeight = 1;
};
const handleKeyCode = (event) => {
  console.log(event);
  if (event.keyCode === 13) {
    if (!event.ctrlKey) {
      event.preventDefault();
      handleSubmit();
    } else {
      value.value += "\n";
    }
  }
};
const chatSendMsg = () => {
  let sign = md5(
    Math.floor(Date.now() / 1000) +
      "TiYksjekO4zAgw6ui1ikdjejDBIKrxK5aOLpg4EEsQL4hBMrEgdFhs"
  );

  const list = [...messageList.value].filter((item) => !item.loading);

  socket.value.send(
    JSON.stringify({
      status: 200,
      report_id: 40001,
      timestamp: Math.floor(Date.now() / 1000).toString(),
      sign: sign,
      messages: list,
    })
  );
  setTimeout(() => {
    saveMessage("user", value.value);
    value.value = "";
    textarea.value.style.height = "";
    textarea.value.style.overflow = "";
    textarea.value.style.height = "";
    textarea.value.style.lineHeight = "";
  }, 300);
};
const disconnectSocket = () => {
  stop();
  messageList.value = [];
};
const stop = () => {
  isWaiting.value = false;
  socketIsOpen.value = false;
  if (socket.value) {
    socket.value.close();
    socket.value = null;
  }
};

const socketInit = () => {
  if (socketIsOpen.value) {
    chatSendMsg();
  } else {
    const path = "wss://jlmodel.huashuyunai.com:8091/chat/queryhistory";
    socket.value = new WebSocket(path);
    socket.value.onopen = () => {
      socketIsOpen.value = true;
      chatSendMsg();
    };

    socket.value.onmessage = (data) => {
      const message = JSON.parse(data.data);
      const index = messageList.value.length - 1;
      const item = messageList.value[index];
      if (item.loading) {
        messageList.value.splice(index, 1, {
          content: "",
          role: "assistant",
        });
      } else {
        messageList.value.splice(index, 1, {
          ...item,
          content: item.content + message.messages[0].content,
        });
      }

      scroll();
    };

    socket.value.onerror = () => {
      socketIsOpen.value = false;
      isWaiting.value = false;
      nextTick(() => {
        scroll();
      });
    };
    socket.value.onclose = () => {
      if (messageList.value.length > 0) {
        const index = messageList.value.length - 1;
        const item = messageList.value[index];
        saveMessage("assistant", item.content);
      }
      socketIsOpen.value = false;
      isWaiting.value = false;
      nextTick(() => {
        scroll();
      });
    };
  }
};
const handleSubmitInstructionSet = (e) => {
  value.value = e;
  createConversation(value.value);
  socketInit();
  isWaiting.value = true;

  messageList.value.push({ role: "user", content: e });
  messageList.value.push({
    content: "智能回复中...",
    role: "assistant",
    loading: true,
  });
  nextTick(() => {
    scroll();
  });
};

const handleSubmit = () => {
  if (isWaiting.value) {
    stop();
  }

  if (!value.value) return;
  if (!socketIsOpen.value && messageList.value.length <= 0) {
    // 开启对话
    createConversation(value.value);
  }
  socketInit();
  isWaiting.value = true;

  // console.log(this.value)
  messageList.value.push({ role: "user", content: value.value });
  messageList.value.push({
    content: "智能回复中...",
    role: "assistant",
    loading: true,
  });
  nextTick(() => {
    scroll();
  });
};
const selectHelp = (item) => {
  value.value = item;
  handleSubmit();
};
const scroll = () => {
  const msgListRef = msgList.value;
  if (msgListRef) {
    // 获取.msg-list元素的scrollHeight
    const scrollHeight = msgListRef.scrollHeight;

    // 滚动到.msg-list底部
    // msgListRef.scrollTop = ;
    msgListRef.scrollTo({
      top: scrollHeight,
      behavior: "smooth",
      duration: 1500,
    });
  }
};
const history = ref(false);
const getHistory = async () => {
  history.value = true;
  messageList.value = [];
  const res = await Api.getMessage({
    conversation_id: store.state.conversation_id,
  });
  if (res.status == 200) {
    if (res.data.length <= 0) {
      return;
    }
    let mes = res.data.map((e) => {
      return {
        role: e.role,
        content: e.content,
      };
    });
    messageList.value = [...messageList.value, ...mes];
    nextTick(() => {
      history.value = false;
      scroll();
    });
  }
};
const saveMessage = async (role, content) => {
  const res = await Api.saveMessage({
    form: {
      conversation_id: store.state.conversation_id,
      role: role,
      content: content,
    },
  });
};

const createConversation = async (title) => {
  const res = await Api.createConversation({
    form: {
      title: title,
    },
  });
  if (res.status == 200) {
    console.log(res);
    store.dispatch("setConversationId", res.data.conversation_id);
    emit("refreshMessageList");
  }
};
const handleAdd = () => {
  if (socketIsOpen.value) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return;
  }
  if (props.type == 0 && !socketIsOpen.value && messageList.value.length <= 0) {
    // 没有选中 && 没有开启对话
    ElMessage({
      message: "已切换最新会话",
      type: "warning",
    });
  } else {
    stop();
    store.dispatch("setConversationId", 0);
    emit("handleAdd");
  }
};
const changeType = (item) => {
  emit("changeType", item);
};
__expose({
  handleSubmitInstructionSet,
  disconnectSocket,
  getHistory,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "msg-list",
      ref_key: "msgList",
      ref: msgList
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messageList.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["msg", { user: item.role === 'user' }]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_2, [
            (item.role == 'assistant')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "avatar-icon",
                  src: avatar.value,
                  alt: ""
                }, null, 8, _hoisted_3))
              : (_openBlock(), _createElementBlock("img", _hoisted_4)),
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.role == "assistant" ? "美湾AI" : "我"), 1)
          ]),
          (item.role == 'assistant')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "content",
                innerHTML: getDom(item.content)
              }, null, 8, _hoisted_6))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.content), 1)),
          (
            (messageList.value.length == 2 && props.type == 1 && !socketIsOpen.value) ||
            showSafe.value
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", {
                  class: "info_btn",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {
              value.value = '请对该配方的法规风险，及可能出现的风险物质进行评估';
              showSafe.value = false;
              handleSubmit();
            })
                }, " 安全评估 "),
                _createElementVNode("div", {
                  class: "info_btn",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {
              value.value =
                '请根据上面的配方方案，生成该配方的详细报价表以及对应厂商和联系方式';
              showSafe.value = true;
              handleSubmit();
            })
                }, " 原料报价 ")
              ]))
            : _createCommentVNode("", true)
        ], 2))
      }), 128)),
      (
          props.type == 0 &&
          !socketIsOpen.value &&
          messageList.value.length <= 0 &&
          !history.value
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "title" }, "你好，我是美湾大模型", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tip" }, " 我将提供专业的咨询及检测服务，助力化妆品企业加速产品研发上市 降低合规风险 ", -1)),
            _createElementVNode("div", _hoisted_10, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "left" }, null, -1)),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value_box.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "r_item",
                    key: index,
                    onClick: ($event: any) => (changeType(item))
                  }, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.name), 1),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(item.tip), 1)
                  ], 8, _hoisted_12))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (props.type == 3 && !socketIsOpen.value && messageList.value.length <= 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mes" }, [
              _createElementVNode("div", { class: "name" }, [
                _createElementVNode("img", {
                  class: "icon",
                  src: _imports_1,
                  alt: ""
                }),
                _createTextVNode(" 安全评估 ")
              ]),
              _createElementVNode("div", { class: "tip" }, " 基于法规和物质信息，生成化妆品安全评估报告，技术团队为报告的客观性与准确性保驾护航 ")
            ], -1)),
            _createElementVNode("div", _hoisted_16, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tip" }, "我可以如何帮到你？", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(helpList.value, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "item",
                  key: index,
                  onClick: ($event: any) => (selectHelp(item))
                }, _toDisplayString(index + 1) + "." + _toDisplayString(item), 9, _hoisted_17))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ], 512),
    _createElementVNode("div", {
      class: "handleAdd",
      onClick: handleAdd
    }, _cache[9] || (_cache[9] = [
      _createElementVNode("img", {
        class: "icon",
        src: _imports_2,
        alt: ""
      }, null, -1),
      _createTextVNode(" 新建对话 ")
    ])),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _withDirectives(_createElementVNode("textarea", {
          rows: "1",
          ref_key: "textarea",
          ref: textarea,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((value).value = $event)),
          placeholder: "向我提问吧",
          onInput: adjustHeight,
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (handleKeyCode($event)))
        }, null, 544), [
          [_vModelText, value.value]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["btn", { waiting: isWaiting.value }]),
          onClick: handleSubmit
        }, null, 2)
      ])
    ])
  ]))
}
}

})