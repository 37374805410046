<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script lang="ts" setup>
import { debounce } from "lodash";

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 100);
    super(callback);
  }
};
</script>

<style lang="less">
@import "@/assets/reset.css";
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
