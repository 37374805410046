import { axios } from '@/utils/request'

const api = {
  getMessage: '/chatMessages/getMessage',
  createConversation: '/chatConversation/add',
  getMessageList: '/chatConversation/getList',
  saveMessage: '/chatMessages/addMessage',
  deleteConversation: '/chatConversation/delete',


}
export function deleteConversation(data) {
  return axios({
    url: api.deleteConversation,
    method: 'post',
    data
  })
}

export function saveMessage(data) {
  return axios({
    url: api.saveMessage,
    method: 'post',
    data
  })
}
export function getMessage(params) {
  return axios({
    url: api.getMessage,
    method: 'get',
    params
  })
}
export function createConversation(data) {
  return axios({
    url: api.createConversation,
    method: 'post',
    data
  })
}
export function getMessageList(params) {
  return axios({
    url: api.getMessageList,
    method: 'get',
    params
  })
}
