<template>
  <div class="menu_box">
    <div class="menu">
      <img
        class="logo"
        src="../../assets/image/logo.png"
        alt=""
        @click="handleAdd"
      />
      <div class="type_box">
        <div
          class="item"
          :class="{ active: item.id === activeIndex }"
          v-for="(item, index) in type"
          :key="index"
          @click="selectType(item)"
        >
          <img class="icon" :src="item.icon" alt="" />
          {{ item.name }}
        </div>
      </div>
      <div class="record">
        <img class="icon" src="../../assets/image/record.png" alt="" />
        <div class="right">
          <div class="label">
            最近对话
            <img class="arrow" src="../../assets/image/arrow.png" alt="" />
          </div>
          <div class="list">
            <div
              class="item"
              :class="{
                active: item.conversation_id === store.state.conversation_id,
              }"
              v-for="(item, index) in record_list"
              :key="index"
              @click="selectRecord(item)"
            >
              <p>{{ item.title }}</p>
              <img
                class="icon"
                src="../../assets/image/delect.png"
                alt=""
                @click.stop="deleteConversation(item.conversation_id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slide" v-if="activeIndex2 != 3 && activeIndex2 != 0">
      <img
        class="close"
        @click="closeSlide"
        src="../../assets//image/close.png"
        alt=""
      />
      <div class="peifang" v-if="activeIndex == 1">
        <div class="mes">
          <div class="name">
            <img class="icon" src="@/assets/image/peifang.png" alt="" />配方助手
          </div>

          <p>
            配方助手可根据产品功效宣称、适用人群、类别等,帮助企业进行配方审核和风险管理
          </p>
        </div>
        <div class="form">
          <el-form
            ref="ruleFormRef"
            :label-position="'top'"
            label-width="80px"
            :model="form"
            :rules="rules"
            scroll-to-error
          >
            <el-form-item label="产品类别" prop="product">
              <el-select
                v-model="form.product"
                placeholder="请选择"
                @change="selectProduct"
              >
                <el-option
                  v-for="item in PEIFANG.PRODUCTS"
                  :key="item.key"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <template v-if="form.product === '防晒霜'">
              <el-form-item label="防晒系数" prop="spf">
                <el-select v-model="form.spf" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.SPF"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="PA防护等级" prop="pa">
                <el-select v-model="form.pa" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.PA"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="肤质" prop="skin">
                <el-select v-model="form.skin" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.SKIN"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="使用场景" prop="scene">
                <el-select v-model="form.scene" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.SCENE"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="销售地域" prop="area">
                <el-select v-model="form.area" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.AREA"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="防护水平">
                <div class="f-box">
                  <div
                    class="f-item"
                    :class="{ active: item.active }"
                    v-for="(item, index) in LEVEL"
                    :key="index"
                    @click="selectLevel(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="其他">
                <div class="f-box">
                  <div
                    class="f-item"
                    :class="{ active: item.active }"
                    v-for="(item, index) in OTHER"
                    :key="index"
                    @click="selectOther(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </el-form-item>
            </template>

            <template v-if="form.product === '祛皱霜'">
              <el-form-item label="细菌总数" prop="tbc">
                <el-select v-model="form.tbc" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.TBC"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="霉菌和酵母菌总数" prop="tmyc">
                <el-select v-model="form.tmyc" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.TMYC"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="铅" prop="Pb">
                <el-select v-model="form.Pb" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.PB"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="砷" prop="As">
                <el-select v-model="form.As" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.AS"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="汞" prop="Hg">
                <el-select v-model="form.Hg" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.HG"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="镉" prop="Cd">
                <el-select v-model="form.Cd" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.CD"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="镍" prop="Ni">
                <el-select v-model="form.Ni" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.NI"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-if="form.product === '祛痘霜'">
              <el-form-item label="细菌总数" prop="tbc">
                <el-select v-model="form.tbc" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.TBC"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="霉菌和酵母菌总数" prop="tmyc2">
                <el-select v-model="form.tmyc2" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.TMYC2"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="皮肤刺激性积分均值" prop="mcosi">
                <el-select v-model="form.mcosi" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.MCOSI"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="光刺激因子" prop="lsf">
                <el-select v-model="form.lsf" placeholder="请选择">
                  <el-option
                    v-for="item in PEIFANG.LSF"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-form>
        </div>
      </div>
      <div class="yuanliao" v-if="activeIndex == 2">
        <div class="mes">
          <div class="name">
            <img
              class="icon"
              src="@/assets/image/yuanliao.png"
              alt=""
            />原料分析
          </div>
          <p>
            配方助手可根据产品功效宣称、适用人群、类别等,帮助企业进行配方审核和风险管理
          </p>
        </div>
        <div class="form">
          <el-form
            ref="ruleFormRef2"
            :label-position="'top'"
            label-width="80px"
            :model="yuanliao_form"
            :rules="rules2"
            scroll-to-error
          >
            <el-form-item label="原料来源" prop="source">
              <el-select v-model="yuanliao_form.source" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原料名称" prop="name">
              <el-select
                v-model="yuanliao_form.name"
                multiple
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                placeholder="请输入原料名称"
              >
                <el-option v-for="item in []"> </el-option>
              </el-select>
              <div class="hot">
                热门分析:
                <p
                  v-for="(item, index) in yuanliao_arr"
                  :key="index"
                  @click="selectYuanliao(item)"
                >
                  {{ item }}
                </p>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="btn_box">
        <div class="btn_item" @click="selectProduct('')">重置</div>
        <div class="btn_item black" style="margin-left: 12px" @click="submit">
          发送
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts" setup>
import { computed, onMounted, reactive, ref, defineEmits, nextTick } from "vue";
import { PEIFANG, YUANLIAO } from "../../common/enum/data/index";
import * as Api from "@/api/index";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

let store = useStore();
const emit = defineEmits(["type", "instructionSet", "getHistory"]);
const activeIndex = ref(0);
const activeIndex2 = ref(0);
const OTHER = ref(PEIFANG.OTHER);
const LEVEL = ref(PEIFANG.LEVEL);
const yuanliao_arr = ["卡波姆", "甘油", "三乙醇胺", "氧化锌"];
const form = ref({
  product: "",
  type: "",
  spf: "",
  pa: "",
  skin: "",
  scene: "",
  area: "",
  level: [],
  other: [],

  tbc: "",
  tmyc: "",
  Pb: "",
  Cd: "",
  As: "",
  Hg: "",
  Ni: "",

  tmyc2: "",
  mcosi: "",
  lsf: "",
});
const yuanliao_form = ref({
  source: "中国原料",
  name: [],
});
const value = ref("");
const value2 = ref("");
const options = ref([
  {
    value: "中国原料",
    label: "中国原料",
  },
]);
const type = ref([
  { name: "配方助手", icon: require("@/assets/image/peifang.png"), id: 1 },
  { name: "原料分析", icon: require("@/assets/image/yuanliao.png"), id: 2 },
  { name: "安全评估", icon: require("@/assets/image/safe.png"), id: 3 },
]);
const ruleFormRef = ref(null);
const ruleFormRef2 = ref(null);
const rules2 = ref({
  name: [{ required: true, message: "请输入原料名称", trigger: "change" }],
  source: [{ required: true, message: "请选择原料来源", trigger: "change" }],
});
const rules = ref({
  product: [{ required: true, message: "请选择产品类别", trigger: "change" }],
  type: [{ required: true, message: "请选择产品类型", trigger: "change" }],
  spf: [{ required: true, message: "请选择产品功效", trigger: "change" }],
  pa: [{ required: true, message: "请选择产品适用人群", trigger: "change" }],
  skin: [{ required: true, message: "请选择产品适用人群", trigger: "change" }],
  scene: [{ required: true, message: "请选择产品适用场景", trigger: "change" }],
  area: [{ required: true, message: "请选择产品适用地区", trigger: "change" }],

  tbc: [{ required: true, message: "请选择", trigger: "change" }],
  tmyc: [{ required: true, message: "请选择", trigger: "change" }],
  Pb: [{ required: true, message: "请选择", trigger: "change" }],
  Cd: [{ required: true, message: "请选择", trigger: "change" }],
  As: [{ required: true, message: "请选择", trigger: "change" }],
  Hg: [{ required: true, message: "请选择", trigger: "change" }],
  Ni: [{ required: true, message: "请选择", trigger: "change" }],

  tmyc2: [{ required: true, message: "请选择", trigger: "change" }],
  mcosi: [{ required: true, message: "请选择", trigger: "change" }],
  lsf: [{ required: true, message: "请选择", trigger: "change" }],
});
const selectProduct = (value) => {
  form.value = {
    product: value,
    type: "",
    spf: "",
    pa: "",
    skin: "",
    scene: "",
    area: "",
    level: [],
    other: [],

    tbc: "",
    tmyc: "",
    Pb: "",
    Cd: "",
    As: "",
    Hg: "",
    Ni: "",

    tmyc2: "",
    mcosi: "",
    lsf: "",
  };
  OTHER.value.forEach((item) => {
    item.active = false;
  });
  LEVEL.value.forEach((item) => {
    item.active = false;
  });
  yuanliao_form.value = {
    source: "中国原料",
    name: [],
  };
};
const selectLevel = (item) => {
  item.active = !item.active;
  if (!item.active) {
    form.value.level = form.value.level.filter((item2) => item2.id != item.id);
  } else {
    form.value.level.push(item);
  }
};

const selectOther = (item) => {
  item.active = !item.active;
  if (!item.active) {
    form.value.other = form.value.other.filter((item2) => item2.id != item.id);
  } else {
    form.value.other.push(item);
  }
};
const handleAdd = () => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  activeIndex.value = 0;
  selectType({ id: 0 });
};
const selectRecord = (item) => {
  if (item.conversation_id == store.state.conversation_id) return;
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  closeSlide();
  activeIndex.value = 0;
  selectType({ id: 0 });
  store.dispatch("setConversationId", item.conversation_id);
  emit("getHistory");
};
const closeSlide = () => {
  activeIndex2.value = 0;

  selectProduct("");
};
const selectType = (item) => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  activeIndex.value = item.id;
  activeIndex2.value = item.id;
  emit("type", activeIndex.value);
  selectProduct("");
};
const submit = () => {
  console.log(activeIndex.value);
  let instructionSet = "";
  if (activeIndex.value == 1) {
    ruleFormRef.value.validate((valid, fields) => {
      console.log(valid);
      if (valid) {
        if (form.value.product == "防晒霜") {
          if (form.value.level.length == 0) {
            ElMessage({
              message: "请选择产品防护水平",
              type: "warning",
            });
            return;
          } else if (form.value.other.length == 0) {
            ElMessage({
              message: "请选择产品其他属性",
              type: "warning",
            });
            return;
          }
          let level = form.value.level.map((item) => item.name).join("、");

          let other = form.value.other.map((item) => item.name).join("、");
          instructionSet = `请帮我做一份化妆品的配方,产品类型是防晒霜,防晒指标
防晒系数SPF:${form.value.spf},
PA防护等级: ${form.value.pa},
防护类型:${level},
肤质:${form.value.skin}
其他 : ${other},

请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        } else if (form.value.product == "祛皱霜") {
          instructionSet = `请帮我做一份化妆品的配方,产品类型是去皱霜,去皱指标细菌总数${form.value.tbc},霉菌和酵母菌总数${form.value.tmyc2},
铅:${form.value.Pb}毫克/千克
砷:${form.value.As}毫克/千克
汞:${form.value.Hg}毫克/千克         
镉:${form.value.Cd}毫克/千克           
镍:${form.value.Ni}毫克/千克  
请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        } else if (form.value.product == "祛痘霜") {
          instructionSet = `请帮我做一份化妆品的配方,产品类型是祛痘霜,祛痘指标细菌总数${form.value.tbc},霉菌和酵母菌总数${form.value.tmyc2},皮肤刺激性积分均值${form.value.mcosi},光刺激因子（PIF）${form.value.lsf}。请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        }
        emit("instructionSet", instructionSet);
        selectProduct("");
        closeSlide();
      }
    });
  } else if (activeIndex.value == 2) {
    ruleFormRef2.value.validate((valid, fields) => {
      if (valid) {
        instructionSet = `本工厂有以下几种化妆品原料：${yuanliao_form.value.name.join(
          "、"
        )} 请根据以上已有的原料结合其他常规原料，组合成一款新的产品`;
        emit("instructionSet", instructionSet);
        selectProduct("");
        closeSlide();
      }
    });
  }
};

const record_list = ref([]);

const getMessageList = async () => {
  const res = await Api.getMessageList({ limit: 99999 });

  if (res.status == 200) {
    record_list.value = res.data.data;
  }
};
const deleteConversation = async (conversation_id) => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  const res = await Api.deleteConversation({
    conversation_id: conversation_id,
  });
  if (res.status == 200) {
    if (conversation_id == store.state.conversation_id) {
      handleAdd();
    }
    ElMessage({
      message: "删除成功",
      type: "success",
    });
    getMessageList();
  }
};
const selectYuanliao = (item) => {
  // 不允许重复
  if (yuanliao_form.value.name.includes(item)) {
    ElMessage({
      message: "请不要重复添加",
      type: "warning",
    });
    return;
  }
  yuanliao_form.value.name.push(item);
};

onMounted(() => {
  getMessageList();
});
defineExpose({
  handleAdd,
  getMessageList,
  selectType,
});
</script>
  
<style lang="less" scoped>
.menu_box {
  display: flex;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  padding: 16px 20px;
  background: #f3f4f6;
  box-sizing: border-box;
  flex-shrink: 0;
  .logo {
    width: 56px;
    margin-left: 12px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .type_box {
    margin-top: 35px;
    margin-bottom: 29px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      padding: 0 14px;
      .icon {
        width: 15px;
        height: 15px;
        margin-right: 12px;
      }
      &.active {
        background: #e6ebf7;
        border-radius: 8px;
      }
      .icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
    }
  }
  .record {
    display: flex;
    height: calc(100% - 30px);
    overflow: hidden;
    .icon {
      width: 24px;
      height: 24px;
    }
    .right {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-bottom: 23px;
      margin-left: 12px;
      margin-top: 2px;

      .arrow {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
    .list {
      flex: 1;
      overflow: auto;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4px 0 12px;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      height: 32px;
      cursor: pointer;
      > p {
        width: 200px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon {
        width: 16px;
        height: 16px;
      }
      &.active {
        font-weight: 500;
        font-size: 12px;
        color: #333333;
        line-height: 17px;
        background: #ffffff;
        border-radius: 8px;
      }
    }
  }
}
.slide {
  position: relative;
  width: 400px;
  height: 100%;
  padding: 80px 20px 76px;
  box-sizing: border-box;
  border-right: 1px solid #e5e5e5;
  overflow: hidden;
  transition: all 0.3s;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .peifang,
  .yuanliao {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    margin-bottom: 20px;

    .mes {
      background: url("../../assets/image/mes_bg.png") no-repeat;
      background-size: 100% 100%;
      width: 360px;
      height: 110px;
      padding: 22px 16px 18px 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
      .name {
        display: flex;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
      > p {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }
  .form {
    flex: 1;
    overflow: auto;
  }
  .hot {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 12px;
    > p {
      margin-left: 12px;
      cursor: pointer;
      &:hover {
        color: #333333;
      }
    }
  }
  /deep/ .el-form-item__label {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.f-box {
  display: flex;
  .f-item {
    padding: 7px 16px;
    background: #f1f2f3;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    cursor: pointer;
    border: 1px solid #f1f2f3;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.active {
      color: #333333;

      border: 1px solid #333333;
    }
  }
}
/deep/.el-tag.el-tag--info {
  --el-tag-bg-color: #fff;
  --el-tag-text-color: #333333;
}
</style>