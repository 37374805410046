
const SPF = [
  15, 30, 50
]

const PA = [
  'PA+', 'PA++', 'PA+++', 'PA++++'
]

const TYPE = [
  '防水', '防汗'
]
const SKIN = [
  '油性', '干性', '敏感'
]

const OTHER = [
  '无刺激', '无香料'
]

const AERA = [
  '中国', '欧洲', '非洲', '北美', '南美'
]

export default {
  SPF,
  PA,
  TYPE,
  SKIN,
  OTHER,
  AERA
}