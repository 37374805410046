import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/image/logo.png'
import _imports_1 from '../../assets/image/record.png'
import _imports_2 from '../../assets/image/arrow.png'
import _imports_3 from '../../assets/image/delect.png'
import _imports_4 from '../../assets//image/close.png'
import _imports_5 from '@/assets/image/peifang.png'
import _imports_6 from '@/assets/image/yuanliao.png'


const _hoisted_1 = { class: "menu_box" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = { class: "type_box" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "record" }
const _hoisted_7 = { class: "right" }
const _hoisted_8 = { class: "list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "slide"
}
const _hoisted_12 = {
  key: 0,
  class: "peifang"
}
const _hoisted_13 = { class: "form" }
const _hoisted_14 = { class: "f-box" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "f-box" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 1,
  class: "yuanliao"
}
const _hoisted_19 = { class: "form" }
const _hoisted_20 = { class: "hot" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "btn_box" }

import { computed, onMounted, reactive, ref, nextTick } from "vue";
import { PEIFANG, YUANLIAO } from "../../common/enum/data/index";
import * as Api from "@/api/index";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ["type", "instructionSet", "getHistory"],
  setup(__props, { expose: __expose, emit: __emit }) {

let store = useStore();
const emit = __emit;
const activeIndex = ref(0);
const activeIndex2 = ref(0);
const OTHER = ref(PEIFANG.OTHER);
const LEVEL = ref(PEIFANG.LEVEL);
const yuanliao_arr = ["卡波姆", "甘油", "三乙醇胺", "氧化锌"];
const form = ref({
  product: "",
  type: "",
  spf: "",
  pa: "",
  skin: "",
  scene: "",
  area: "",
  level: [],
  other: [],

  tbc: "",
  tmyc: "",
  Pb: "",
  Cd: "",
  As: "",
  Hg: "",
  Ni: "",

  tmyc2: "",
  mcosi: "",
  lsf: "",
});
const yuanliao_form = ref({
  source: "中国原料",
  name: [],
});
const value = ref("");
const value2 = ref("");
const options = ref([
  {
    value: "中国原料",
    label: "中国原料",
  },
]);
const type = ref([
  { name: "配方助手", icon: require("@/assets/image/peifang.png"), id: 1 },
  { name: "原料分析", icon: require("@/assets/image/yuanliao.png"), id: 2 },
  { name: "安全评估", icon: require("@/assets/image/safe.png"), id: 3 },
]);
const ruleFormRef = ref(null);
const ruleFormRef2 = ref(null);
const rules2 = ref({
  name: [{ required: true, message: "请输入原料名称", trigger: "change" }],
  source: [{ required: true, message: "请选择原料来源", trigger: "change" }],
});
const rules = ref({
  product: [{ required: true, message: "请选择产品类别", trigger: "change" }],
  type: [{ required: true, message: "请选择产品类型", trigger: "change" }],
  spf: [{ required: true, message: "请选择产品功效", trigger: "change" }],
  pa: [{ required: true, message: "请选择产品适用人群", trigger: "change" }],
  skin: [{ required: true, message: "请选择产品适用人群", trigger: "change" }],
  scene: [{ required: true, message: "请选择产品适用场景", trigger: "change" }],
  area: [{ required: true, message: "请选择产品适用地区", trigger: "change" }],

  tbc: [{ required: true, message: "请选择", trigger: "change" }],
  tmyc: [{ required: true, message: "请选择", trigger: "change" }],
  Pb: [{ required: true, message: "请选择", trigger: "change" }],
  Cd: [{ required: true, message: "请选择", trigger: "change" }],
  As: [{ required: true, message: "请选择", trigger: "change" }],
  Hg: [{ required: true, message: "请选择", trigger: "change" }],
  Ni: [{ required: true, message: "请选择", trigger: "change" }],

  tmyc2: [{ required: true, message: "请选择", trigger: "change" }],
  mcosi: [{ required: true, message: "请选择", trigger: "change" }],
  lsf: [{ required: true, message: "请选择", trigger: "change" }],
});
const selectProduct = (value) => {
  form.value = {
    product: value,
    type: "",
    spf: "",
    pa: "",
    skin: "",
    scene: "",
    area: "",
    level: [],
    other: [],

    tbc: "",
    tmyc: "",
    Pb: "",
    Cd: "",
    As: "",
    Hg: "",
    Ni: "",

    tmyc2: "",
    mcosi: "",
    lsf: "",
  };
  OTHER.value.forEach((item) => {
    item.active = false;
  });
  LEVEL.value.forEach((item) => {
    item.active = false;
  });
  yuanliao_form.value = {
    source: "中国原料",
    name: [],
  };
};
const selectLevel = (item) => {
  item.active = !item.active;
  if (!item.active) {
    form.value.level = form.value.level.filter((item2) => item2.id != item.id);
  } else {
    form.value.level.push(item);
  }
};

const selectOther = (item) => {
  item.active = !item.active;
  if (!item.active) {
    form.value.other = form.value.other.filter((item2) => item2.id != item.id);
  } else {
    form.value.other.push(item);
  }
};
const handleAdd = () => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  activeIndex.value = 0;
  selectType({ id: 0 });
};
const selectRecord = (item) => {
  if (item.conversation_id == store.state.conversation_id) return;
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  closeSlide();
  activeIndex.value = 0;
  selectType({ id: 0 });
  store.dispatch("setConversationId", item.conversation_id);
  emit("getHistory");
};
const closeSlide = () => {
  activeIndex2.value = 0;

  selectProduct("");
};
const selectType = (item) => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  activeIndex.value = item.id;
  activeIndex2.value = item.id;
  emit("type", activeIndex.value);
  selectProduct("");
};
const submit = () => {
  console.log(activeIndex.value);
  let instructionSet = "";
  if (activeIndex.value == 1) {
    ruleFormRef.value.validate((valid, fields) => {
      console.log(valid);
      if (valid) {
        if (form.value.product == "防晒霜") {
          if (form.value.level.length == 0) {
            ElMessage({
              message: "请选择产品防护水平",
              type: "warning",
            });
            return;
          } else if (form.value.other.length == 0) {
            ElMessage({
              message: "请选择产品其他属性",
              type: "warning",
            });
            return;
          }
          let level = form.value.level.map((item) => item.name).join("、");

          let other = form.value.other.map((item) => item.name).join("、");
          instructionSet = `请帮我做一份化妆品的配方,产品类型是防晒霜,防晒指标
防晒系数SPF:${form.value.spf},
PA防护等级: ${form.value.pa},
防护类型:${level},
肤质:${form.value.skin}
其他 : ${other},

请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        } else if (form.value.product == "祛皱霜") {
          instructionSet = `请帮我做一份化妆品的配方,产品类型是去皱霜,去皱指标细菌总数${form.value.tbc},霉菌和酵母菌总数${form.value.tmyc2},
铅:${form.value.Pb}毫克/千克
砷:${form.value.As}毫克/千克
汞:${form.value.Hg}毫克/千克         
镉:${form.value.Cd}毫克/千克           
镍:${form.value.Ni}毫克/千克  
请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        } else if (form.value.product == "祛痘霜") {
          instructionSet = `请帮我做一份化妆品的配方,产品类型是祛痘霜,祛痘指标细菌总数${form.value.tbc},霉菌和酵母菌总数${form.value.tmyc2},皮肤刺激性积分均值${form.value.mcosi},光刺激因子（PIF）${form.value.lsf}。请根据以上的信息和需求,整理一个包含标准中文名称、INCI、原料含量、成分含量、实际含量、使用目的等内容的配方表。`;
        }
        emit("instructionSet", instructionSet);
        selectProduct("");
        closeSlide();
      }
    });
  } else if (activeIndex.value == 2) {
    ruleFormRef2.value.validate((valid, fields) => {
      if (valid) {
        instructionSet = `本工厂有以下几种化妆品原料：${yuanliao_form.value.name.join(
          "、"
        )} 请根据以上已有的原料结合其他常规原料，组合成一款新的产品`;
        emit("instructionSet", instructionSet);
        selectProduct("");
        closeSlide();
      }
    });
  }
};

const record_list = ref([]);

const getMessageList = async () => {
  const res = await Api.getMessageList({ limit: 99999 });

  if (res.status == 200) {
    record_list.value = res.data.data;
  }
};
const deleteConversation = async (conversation_id) => {
  if (store.state.socket_is_open) {
    ElMessage({
      message: "当前对话正在进行中",
      type: "warning",
    });
    return false;
  }
  const res = await Api.deleteConversation({
    conversation_id: conversation_id,
  });
  if (res.status == 200) {
    if (conversation_id == store.state.conversation_id) {
      handleAdd();
    }
    ElMessage({
      message: "删除成功",
      type: "success",
    });
    getMessageList();
  }
};
const selectYuanliao = (item) => {
  // 不允许重复
  if (yuanliao_form.value.name.includes(item)) {
    ElMessage({
      message: "请不要重复添加",
      type: "warning",
    });
    return;
  }
  yuanliao_form.value.name.push(item);
};

onMounted(() => {
  getMessageList();
});
__expose({
  handleAdd,
  getMessageList,
  selectType,
});

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "logo",
        src: _imports_0,
        alt: "",
        onClick: handleAdd
      }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(type.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["item", { active: item.id === activeIndex.value }]),
            key: index,
            onClick: ($event: any) => (selectType(item))
          }, [
            _createElementVNode("img", {
              class: "icon",
              src: item.icon,
              alt: ""
            }, null, 8, _hoisted_5),
            _createTextVNode(" " + _toDisplayString(item.name), 1)
          ], 10, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[21] || (_cache[21] = _createElementVNode("img", {
          class: "icon",
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_7, [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "label" }, [
            _createTextVNode(" 最近对话 "),
            _createElementVNode("img", {
              class: "arrow",
              src: _imports_2,
              alt: ""
            })
          ], -1)),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record_list.value, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["item", {
                active: item.conversation_id === _unref(store).state.conversation_id,
              }]),
                key: index,
                onClick: ($event: any) => (selectRecord(item))
              }, [
                _createElementVNode("p", null, _toDisplayString(item.title), 1),
                _createElementVNode("img", {
                  class: "icon",
                  src: _imports_3,
                  alt: "",
                  onClick: _withModifiers(($event: any) => (deleteConversation(item.conversation_id)), ["stop"])
                }, null, 8, _hoisted_10)
              ], 10, _hoisted_9))
            }), 128))
          ])
        ])
      ])
    ]),
    (activeIndex2.value != 3 && activeIndex2.value != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("img", {
            class: "close",
            onClick: closeSlide,
            src: _imports_4,
            alt: ""
          }),
          (activeIndex.value == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[22] || (_cache[22] = _createElementVNode("div", { class: "mes" }, [
                  _createElementVNode("div", { class: "name" }, [
                    _createElementVNode("img", {
                      class: "icon",
                      src: _imports_5,
                      alt: ""
                    }),
                    _createTextVNode("配方助手 ")
                  ]),
                  _createElementVNode("p", null, " 配方助手可根据产品功效宣称、适用人群、类别等,帮助企业进行配方审核和风险管理 ")
                ], -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_el_form, {
                    ref_key: "ruleFormRef",
                    ref: ruleFormRef,
                    "label-position": 'top',
                    "label-width": "80px",
                    model: form.value,
                    rules: rules.value,
                    "scroll-to-error": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "产品类别",
                        prop: "product"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: form.value.product,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.product) = $event)),
                            placeholder: "请选择",
                            onChange: selectProduct
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).PRODUCTS, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.key,
                                  label: item.name,
                                  value: item.name
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      (form.value.product === '防晒霜')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_el_form_item, {
                              label: "防晒系数",
                              prop: "spf"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.spf,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.spf) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).SPF, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "PA防护等级",
                              prop: "pa"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.pa,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.pa) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).PA, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "肤质",
                              prop: "skin"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.skin,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.skin) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).SKIN, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "使用场景",
                              prop: "scene"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.scene,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.scene) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).SCENE, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "销售地域",
                              prop: "area"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.area,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.value.area) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).AREA, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, { label: "防护水平" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_14, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(LEVEL.value, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: _normalizeClass(["f-item", { active: item.active }]),
                                      key: index,
                                      onClick: ($event: any) => (selectLevel(item))
                                    }, _toDisplayString(item.name), 11, _hoisted_15))
                                  }), 128))
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, { label: "其他" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_16, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(OTHER.value, (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: _normalizeClass(["f-item", { active: item.active }]),
                                      key: index,
                                      onClick: ($event: any) => (selectOther(item))
                                    }, _toDisplayString(item.name), 11, _hoisted_17))
                                  }), 128))
                                ])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true),
                      (form.value.product === '祛皱霜')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_component_el_form_item, {
                              label: "细菌总数",
                              prop: "tbc"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.tbc,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.tbc) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).TBC, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "霉菌和酵母菌总数",
                              prop: "tmyc"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.tmyc,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.value.tmyc) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).TMYC, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "铅",
                              prop: "Pb"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.Pb,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((form.value.Pb) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).PB, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "砷",
                              prop: "As"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.As,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((form.value.As) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).AS, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "汞",
                              prop: "Hg"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.Hg,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((form.value.Hg) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).HG, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "镉",
                              prop: "Cd"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.Cd,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((form.value.Cd) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).CD, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "镍",
                              prop: "Ni"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.Ni,
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((form.value.Ni) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).NI, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true),
                      (form.value.product === '祛痘霜')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createVNode(_component_el_form_item, {
                              label: "细菌总数",
                              prop: "tbc"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.tbc,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((form.value.tbc) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).TBC, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "霉菌和酵母菌总数",
                              prop: "tmyc2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.tmyc2,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((form.value.tmyc2) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).TMYC2, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "皮肤刺激性积分均值",
                              prop: "mcosi"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.mcosi,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((form.value.mcosi) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).MCOSI, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_form_item, {
                              label: "光刺激因子",
                              prop: "lsf"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  modelValue: form.value.lsf,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((form.value.lsf) = $event)),
                                  placeholder: "请选择"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PEIFANG).LSF, (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item,
                                        label: item,
                                        value: item
                                      }, null, 8, ["label", "value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["model", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          (activeIndex.value == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _cache[24] || (_cache[24] = _createElementVNode("div", { class: "mes" }, [
                  _createElementVNode("div", { class: "name" }, [
                    _createElementVNode("img", {
                      class: "icon",
                      src: _imports_6,
                      alt: ""
                    }),
                    _createTextVNode("原料分析 ")
                  ]),
                  _createElementVNode("p", null, " 配方助手可根据产品功效宣称、适用人群、类别等,帮助企业进行配方审核和风险管理 ")
                ], -1)),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_el_form, {
                    ref_key: "ruleFormRef2",
                    ref: ruleFormRef2,
                    "label-position": 'top',
                    "label-width": "80px",
                    model: yuanliao_form.value,
                    rules: rules2.value,
                    "scroll-to-error": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: "原料来源",
                        prop: "source"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: yuanliao_form.value.source,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((yuanliao_form.value.source) = $event)),
                            placeholder: "请选择"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.label,
                                  label: item.label,
                                  value: item.label
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, {
                        label: "原料名称",
                        prop: "name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: yuanliao_form.value.name,
                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((yuanliao_form.value.name) = $event)),
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            "reserve-keyword": false,
                            placeholder: "请输入原料名称"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([], (item) => {
                                return _createVNode(_component_el_option)
                              }), 64))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createElementVNode("div", _hoisted_20, [
                            _cache[23] || (_cache[23] = _createTextVNode(" 热门分析: ")),
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yuanliao_arr, (item, index) => {
                              return _createElementVNode("p", {
                                key: index,
                                onClick: ($event: any) => (selectYuanliao(item))
                              }, _toDisplayString(item), 9, _hoisted_21)
                            }), 64))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", {
              class: "btn_item",
              onClick: _cache[19] || (_cache[19] = ($event: any) => (selectProduct('')))
            }, "重置"),
            _createElementVNode("div", {
              class: "btn_item black",
              style: {"margin-left":"12px"},
              onClick: submit
            }, " 发送 ")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})