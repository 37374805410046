/**
 * 工具类
 */
/**
* 格式化日期格式 (用于兼容ios Date对象)
*/
export const formatDate = (time) => {
  // 将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式
  return time.replace(/\-/g, "/");
};

/**
* 对象转URL
* @param {object} obj
*/
export const urlEncode = (obj = {}) => {
  const result = [];
  for (const key in obj) {
    const item = obj[key];
    if (!item) {
      continue;
    }
    if (isArray(item)) {
      item.forEach((val) => {
        result.push(key + "=" + val);
      });
    } else {
      result.push(key + "=" + item);
    }
  }
  return result.join("&");
};

/**
* 遍历对象
*/
export const objForEach = (obj, callback) => {
  Object.keys(obj).forEach((key) => {
    callback(obj[key], key);
  });
};

/**
* 是否在数组内
*/
export const inArray = (search, array) => {
  for (var i in array) {
    if (array[i] == search) return true;
  }
  return false;
};

/**
* 对Date的扩展，将 Date 转化为指定格式的String
* 月(Y)、月(m)、日(d)、小时(H)、分(M)、秒(S) 可以用 1-2 个占位符，
* 例子：
* dateFormat('YYYY-mm-dd HH:MM:SS', new Date()) ==> 2020-01-01 08:00:00
*/
export const dateFormat = (fmt, date) => {
  let datenew = new Date(date.replace(/-/g, '/'));
  const opt = {
    "Y+": datenew.getFullYear().toString(), // 年
    "m+": (datenew.getMonth() + 1).toString(), // 月
    "d+": datenew.getDate().toString(), // 日
    "H+": datenew.getHours().toString(), // 时
    "M+": datenew.getMinutes().toString(), // 分
    "S+": datenew.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  let ret;
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};

/**
* 判断是否为空对象
* @param {*} object 源对象
*/
export const isEmptyObject = (object) => {
  return Object.keys(object).length === 0;
};

/**
* 判断是否为对象
* @param {*} object
*/
export const isObject = (object) => {
  return Object.prototype.toString.call(object) === "[object Object]";
};

/**
* 判断是否为数组
* @param {*} array
*/
export const isArray = (array) => {
  return Object.prototype.toString.call(array) === "[object Array]";
};

/**
* 判断是否为空
* @param {*} object 源对象
*/
export const isEmpty = (value) => {
  if (isArray(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return isEmptyObject(value);
  }
  return !value;
};

/**
* 对象深拷贝
* @param {*} obj 源对象
*/
export const cloneObj = (obj) => {
  let newObj = obj.constructor === Array ? [] : {};
  if (typeof obj !== "object") {
    return;
  }
  for (let i in obj) {
    newObj[i] = typeof obj[i] === "object" ? cloneObj(obj[i]) : obj[i];
  }
  return newObj;
};

// 节流函数
// 思路： 第一次先设定一个变量true，
// 第二次执行这个函数时，会判断变量是否true，
// 是则返回。当第一次的定时器执行完函数最后会设定变量为flase。
// 那么下次判断变量时则为flase，函数会依次运行。
export function throttle(fn, delay = 100) {
  // 首先设定一个变量，在没有执行我们的定时器时为null
  var timer = null;
  return function () {
    // 当我们发现这个定时器存在时，则表示定时器已经在运行中，需要返回
    if (timer) return;
    timer = setTimeout(() => {
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
}

// 防抖函数
// 首次运行时把定时器赋值给一个变量， 第二次执行时，
// 如果间隔没超过定时器设定的时间则会清除掉定时器，
// 重新设定定时器， 依次反复， 当我们停止下来时，
// 没有执行清除定时器， 超过一定时间后触发回调函数。
// 参考文档：https://segmentfault.com/q/1010000021145192
export function debounce(fn, delay) {
  let timer;
  return function () {
    const that = this;
    const _args = arguments; // 存一下传入的参数
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      fn.apply(that, _args);
    }, delay);
  };
}

/**
* 数组交集
* @param {Array} 数组1
* @param {Array} 数组2
* @return {Array}
*/
export const arrayIntersect = (array1, array2) => {
  return array1.filter((val) => array2.indexOf(val) > -1);
};

/**
* 数字转金额
* @param {金额} number
* @returns
*/
export const fmoney = (number) => {
  number = String(number);
  return number.match(/^\d*(\.?\d{0,2})/g)[0];
};


export const getBoj = (str) => {
  const obj = JSON.parse(str)
  if (typeof obj === "string") {
    return getBoj(obj)
  } else {
    return obj
  }
}


export const strToArrayBuffer = (str) => {
  var array = new Uint8Array(str.length);
  for (var i = 0; i < str.length; i++) {
    array[i] = str.charCodeAt(i);
  }
  return array.buffer
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

export function stringToBlob(str) {
  let mimeType = 'application/octet-stream'; // 默认类型为 'application/octet-stream'
  const bytes = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return new Blob([bytes], { type: mimeType });
}

export const shuffle = (array) => {
  let m = array.length,
    t, i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
}

export function compress(fileObj) {
  return new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(fileObj)
    reader.onload = function (e) {
      let image = new Image()     //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
      image.src = e.target.result    //让该标签加载base64格式的原图
      image.onload = function () {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
        let canvas = document.createElement('canvas'), //创建一个canvas元素
          context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
          imageWidth = image.width / 2,    //压缩后图片的宽度，这里设置为缩小一半
          imageHeight = image.height / 2
        canvas.width = imageWidth    //设置绘图的宽度
        canvas.height = imageHeight    //设置绘图的高度

        //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
        context.drawImage(image, 0, 0, imageWidth, imageHeight)
        //使用toDataURL将canvas上的图片转换为base64格式
        resolve(canvas.toDataURL("image/jpeg"))
      }
    }
  })
}

function getTableTr(text) {
  const list = text.split('|')
  return list.reduce((prev, cur) => {
    if (cur) {
      prev += `<td class="td">${cur}</td>`
    }
    return prev
  }, '')
}

export function getTextDom(text) {
  // 表格
  let domString = ''
  let isTableBegin = false
  if (/--\|--/g.test(text) || /-- \| --/g.test(text) || /--: \| :--/g.test(text) || /--:\|:--/g.test(text)) {
    const list = text.split('\n')
    for (let index = 0; index < list.length; index++) {
      const element = list[index]
      if (element.startsWith('|-') || element.startsWith('| -') || element.startsWith('| :-') || element.startsWith('|:-')) {
        domString += ''
      } else if (!element.startsWith('| ')) {
        if (isTableBegin) { // 表格进行中
          domString += `</table></div><p>${element}</p>`
        } else {
          domString += `<p>${element}</p>`
        }
        isTableBegin = false
      } else if (isTableBegin) { // 表格已经开始
        if (index < list.length - 1) {
          domString += `<tr class="tr">${getTableTr(element)}</tr>`
        } else {
          domString += `<tr class="tr">${getTableTr(element)}</tr></table>`
        }
      } else { // 表格开始
        isTableBegin = true
        domString += `<div class="t_box"><table class="table"><tr class="tr">${getTableTr(element)}</tr>`
      }
    }
  } else {
    domString += text
  }
  return domString
}