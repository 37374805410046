import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

import { computed, onMounted, reactive, ref, nextTick } from "vue";
import Menu from "../menu";
import Chat from "../chat";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const menuRef = ref(null);
const chatRef = ref(null);
const type = ref(0);
const handleAdd = () => {
  menuRef.value.handleAdd();
};

const refreshMessageList = () => {
  menuRef.value.getMessageList();
};
const changeType = (value) => {
  menuRef.value.selectType(value);
};
const getHistory = () => {
  chatRef.value.getHistory();
};

const getType = (value) => {
  type.value = value;
  chatRef.value.disconnectSocket();
};
const getInstructionSet = (value) => {
  chatRef.value.handleSubmitInstructionSet(value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Menu), {
      ref_key: "menuRef",
      ref: menuRef,
      onType: getType,
      onInstructionSet: getInstructionSet,
      onGetHistory: getHistory
    }, null, 512),
    _createVNode(_unref(Chat), {
      ref_key: "chatRef",
      ref: chatRef,
      type: type.value,
      onChangeType: changeType,
      onHandleAdd: handleAdd,
      onRefreshMessageList: refreshMessageList
    }, null, 8, ["type"])
  ]))
}
}

})