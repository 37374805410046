const publicConfig = {
  // 系统名称
  APP_NAME: '美妆',
  // BASE_API: 'https://shitingyun.huashuyunai.com/index.php?s=/store',
  BASE_API: 'https://meizhuang.bc2c.cn/index.php?s=/api', // 测试站接口域名
  WSS_API: 'wss://jlmodel.huashuyunai.com:8091/chat/queryhistory',// 正式站接口域名
  // WSS_API: 'wss://chatmin.bc2c.cn:8191/chat/queryhistory',// 测试站接口域名,
  MD: 'TiYksjekO4zAgw6ui1ikdjejDBIKrxK5aOLpg4EEsQL4hBMrEgdFhs',
  // md: 'TiYksjekO4zAgw6ui1ikdjejDBIKrxK5aOLpg4EEsQL4hBMrEgdFhs',
}
export default publicConfig