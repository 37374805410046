import { createStore } from 'vuex'

export default createStore({
  state: {
    conversation_id: 0,
    socket_is_open: false,
  },
  getters: {
  },
  mutations: {
    SETID(state, data) {
      state.conversation_id = data
    },
    SET_SOCKET_IS_OPEN(state, data) {
      state.socket_is_open = data
    }
  },
  actions: {
    setConversationId({ commit }, data) {
      commit('SETID', data)
    },
    setSocketIsOpen({ commit }, data) {
      commit('SET_SOCKET_IS_OPEN', data)
    }

  },
  modules: {
  }
})
